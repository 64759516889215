import { useState, useEffect } from "react";
import {
    useNavigate
} from "react-router-dom";

import Loader from '../../../components/Loader'

import { getStudents } from "../../../http";

const S2019Batch = () => {
    const navigate = useNavigate();
    const [seaechInput, setSearchInput] = useState('');
    const [s2019s, setS2019s] = useState();
    const [filteredStudents, setFilteredStudents] = useState();


    useEffect(async () => {
        const stu = [
            {
                "rN0": "2019NITSGR0135",
                "enroll": "2019BITE001",
                "name": "SUDHANSHU BANBOTRA"
            },
            {
                "rN0": "2019NITSGR0168",
                "enroll": "2019BITE003",
                "name": "SURAJ KANNUJIYA"
            },
            {
                "rN0": "2019NITSGR0173",
                "enroll": "2019BITE004",
                "name": "VAGISHA MISRA"
            },
            {
                "rN0": "2019NITSGR0195",
                "enroll": "2019BITE005",
                "name": "NIKHIL VIRDHI"
            },
            {
                "rN0": "2019NITSGR0208",
                "enroll": "2019BITE006",
                "name": "VISHAL KUMAR"
            },
            {
                "rN0": "2019NITSGR0213",
                "enroll": "2019BITE007",
                "name": "ABIJIT SINGH"
            },
            {
                "rN0": "2019NITSGR0217",
                "enroll": "2019BITE008",
                "name": "ABHISHEK BAHUGUNA"
            },
            {
                "rN0": "2019NITSGR0220",
                "enroll": "2019BITE010",
                "name": "ISHIKA GUPTA"
            },
            {
                "rN0": "2019NITSGR0236",
                "enroll": "2019BITE011",
                "name": "RITA DAS"
            },
            {
                "rN0": "2019NITSGR0249",
                "enroll": "2019BITE012",
                "name": "TUSHAR KUMAR"
            },
            {
                "rN0": "2019NITSGR0257",
                "enroll": "2019BITE013",
                "name": "TAHOOR YASEEN AHANGER"
            },
            {
                "rN0": "2019NITSGR0290",
                "enroll": "2019BITE014",
                "name": "RETHIK CHOUDHARY"
            },
            {
                "rN0": "2019NITSGR0293",
                "enroll": "2019BITE015",
                "name": "DAMANDEEP SINGH"
            },
            {
                "rN0": "2019NITSGR0297",
                "enroll": "2019BITE016",
                "name": "PRANAV SINGH SAMBYAL"
            },
            {
                "rN0": "2019NITSGR0318",
                "enroll": "2019BITE017",
                "name": "KESHAV SHARMA"
            },
            {
                "rN0": "2019NITSGR0324",
                "enroll": "2019BITE018",
                "name": "DEEPAK KUMAR"
            },
            {
                "rN0": "2019NITSGR0326",
                "enroll": "2019BITE019",
                "name": "VISHAL KUMAR"
            },
            {
                "rN0": "2019NITSGR0333",
                "enroll": "2019BITE020",
                "name": "NOEL SAJ"
            },
            {
                "rN0": "2019NITSGR0334",
                "enroll": "2019BITE021",
                "name": "PIYUSH THANDIRA"
            },
            {
                "rN0": "2019NITSGR0342",
                "enroll": "2019BITE022",
                "name": "IRTIQA REYAZ"
            },
            {
                "rN0": "2019NITSGR0351",
                "enroll": "2019BITE023",
                "name": "AMAN JANDIAL"
            },
            {
                "rN0": "2019NITSGR0359",
                "enroll": "2019BITE024",
                "name": "AHSAN UL HAQ"
            },
            {
                "rN0": "2019NITSGR0360",
                "enroll": "2019BITE025",
                "name": "MOHD ANAS"
            },
            {
                "rN0": "2019NITSGR0364",
                "enroll": "2019BITE026",
                "name": "MUNEER AHMAD GANAIE"
            },
            {
                "rN0": "2019NITSGR0371",
                "enroll": "2019BITE027",
                "name": "ADITYA SHIVAJI VIBHUTE"
            },
            {
                "rN0": "2019NITSGR0383",
                "enroll": "2019BITE029",
                "name": "SHAHID NAZIR"
            },
            {
                "rN0": "2019NITSGR0396",
                "enroll": "2019BITE030",
                "name": "VARUNESH CHANDER"
            },
            {
                "rN0": "2019NITSGR0401",
                "enroll": "2019BITE031",
                "name": "RAJ SINGH RATHOUR"
            },
            {
                "rN0": "2019NITSGR0420",
                "enroll": "2019BITE032",
                "name": "ARNAV RAJ"
            },
            {
                "rN0": "2019NITSGR0423",
                "enroll": "2019BITE033",
                "name": "ABHINAV SHUKLA"
            },
            {
                "rN0": "2019NITSGR0427",
                "enroll": "2019BITE034",
                "name": "KHALID SALIM"
            },
            {
                "rN0": "2019NITSGR0459",
                "enroll": "2019BITE035",
                "name": "TABREZ ALAM"
            },
            {
                "rN0": "2019NITSGR0461",
                "enroll": "2019BITE036",
                "name": "KARAN PAL"
            },
            {
                "rN0": "2019NITSGR0484",
                "enroll": "2019BITE037",
                "name": "RIYAZ HYDER SHEIKH"
            },
            {
                "rN0": "2019NITSGR0498",
                "enroll": "2019BITE038",
                "name": "ELAPROLU MOHITH NAGA CHANDU"
            },
            {
                "rN0": "2019NITSGR0515",
                "enroll": "2019BITE039",
                "name": "HIMANSHU NETAM"
            },
            {
                "rN0": "2019NITSGR0566",
                "enroll": "2019BITE040",
                "name": "MAYANK GUPTA"
            },
            {
                "rN0": "2019NITSGR0572",
                "enroll": "2019BITE041",
                "name": "IFRAH SYED"
            },
            {
                "rN0": "2019NITSGR0603",
                "enroll": "2019BITE042",
                "name": "RAKSHIT GUPTA"
            },
            {
                "rN0": "2019NITSGR0608",
                "enroll": "2019BITE043",
                "name": "DHANANJAY KUMAR MISHRA"
            },
            {
                "rN0": "2019NITSGR0633",
                "enroll": "2019BITE044",
                "name": "DIVYA MAHAJAN"
            },
            {
                "rN0": "2019NITSGR0639",
                "enroll": "2019BITE045",
                "name": "SWATI"
            },
            {
                "rN0": "2019NITSGR0645",
                "enroll": "2019BITE046",
                "name": "NAVNEET KHAR"
            },
            {
                "rN0": "2019NITSGR0671",
                "enroll": "2019BITE047",
                "name": "VARTIKA"
            },
            {
                "rN0": "2019NITSGR0697",
                "enroll": "2019BITE048",
                "name": "PRATEEK VIJAY"
            },
            {
                "rN0": "2019NITSGR0714",
                "enroll": "2019BITE049",
                "name": "AKSHAT DUBEY"
            },
            {
                "rN0": "2019NITSGR0718",
                "enroll": "2019BITE050",
                "name": "AJIT SINGH"
            },
            {
                "rN0": "2019NITSGR0721",
                "enroll": "2019BITE051",
                "name": "KAPIL KUMAR"
            },
            {
                "rN0": "2019NITSGR0725",
                "enroll": "2019BITE052",
                "name": "NEERAJ MEHRA"
            },
            {
                "rN0": "2019NITSGR0728",
                "enroll": "2019BITE053",
                "name": "AJAY"
            },
            {
                "rN0": "2019NITSGR0732",
                "enroll": "2019BITE054",
                "name": "JATIN SAINI"
            },
            {
                "rN0": "2019NITSGR0736",
                "enroll": "2019BITE056",
                "name": "SHREYASH PAL"
            },
            {
                "rN0": "2019NITSGR0738",
                "enroll": "2019BITE057",
                "name": "RAKSHIT KUMAR SHARMA"
            },
            {
                "rN0": "2019NITSGR0742",
                "enroll": "2019BITE058",
                "name": "NIRBHAY MAURYA"
            },
            {
                "rN0": "2019NITSGR0745",
                "enroll": "2019BITE059",
                "name": "ASHUTOSH TRIPATHI"
            },
            {
                "rN0": "2019NITSGR0749",
                "enroll": "2019BITE060",
                "name": "PATEL REET JAYESHKUMAR"
            },
            {
                "rN0": "2019NITSGR0754",
                "enroll": "2019BITE061",
                "name": "SHAZIA PERWEEN"
            },
            {
                "rN0": "2019NITSGR0760",
                "enroll": "2019BITE062",
                "name": "KANCHAN SAHU"
            },
            {
                "rN0": "2019NITSGR0764",
                "enroll": "2019BITE063",
                "name": "PAWAN KUMAR SHARMA"
            },
            {
                "rN0": "2019NITSGR0768",
                "enroll": "2019BITE064",
                "name": "PRATHMESH PADWAL"
            },
            {
                "rN0": "2019NITSGR0771",
                "enroll": "2019BITE065",
                "name": "VINAY KUMAR SINGH"
            },
            {
                "rN0": "2019NITSGR0782",
                "enroll": "2019BITE066",
                "name": "SURAJ PACHOURI"
            },
            {
                "rN0": "2019NITSGR0786",
                "enroll": "2019BITE067",
                "name": "RAVISH KUMAR"
            },
            {
                "rN0": "2019NITSGR0793",
                "enroll": "2019BITE068",
                "name": "KONDAMUDI PRIYANKA"
            },
            {
                "rN0": "2019NITSGR0796",
                "enroll": "2019BITE069",
                "name": "HIMANSHU SAHU"
            },
            {
                "rN0": "2019NITSGR0806",
                "enroll": "2019BITE070",
                "name": "SUKRATI SAXENA"
            },
            {
                "rN0": "2019NITSGR0776",
                "enroll": "2019BITE072",
                "name": "ANAS MURTAZA"
            }
        ];
        setS2019s(stu);
        setFilteredStudents(stu);
    }, [])

    const filterSearch = (value) => {
        setSearchInput(value);
        let filterData;
        if (value.includes("2019"))
            filterData = s2019s.filter(stdnt => stdnt.enroll.toLowerCase().includes(value.toLowerCase()));
        else
            filterData = s2019s.filter(stdnt => stdnt.name.toLowerCase().includes(value.toLowerCase()));
        setFilteredStudents(filterData);
    }

    return (
        // <Loader />
        <div className="batch2019 container1">
            <div className="history">
                <i className="fa-solid fa-circle-left" onClick={() => navigate(-1)}></i>
                <i className="fa-solid fa-circle-right" onClick={() => navigate(1)}></i>
            </div>
            <div className=" container-fluid container2">
                <h2 className="page_title" style={{ fontSize: "20px", fontWeight: "bold", width: "90%", margin: "0 auto", borderBottom: "1px dotted gray" }}>2019 IT Students</h2>
                <div className="wrap">
                    <div className="search">
                        <input type="text" className="searchTerm" placeholder="Search by Name or Enroll" value={seaechInput} onChange={(e) => { filterSearch(e.target.value) }} />
                        <button type="submit" className="searchButton">
                            <i className="fa fa-search"></i>
                        </button>
                    </div>
                </div>
                <div className="batch2019-table-scroll">
                    {(filteredStudents === undefined) ?
                        <Loader />
                        :
                        <table id="customers">
                            <thead>
                                <tr className="head">
                                    <th>ID</th>
                                    <th>Registration Number</th>
                                    <th>Name</th>
                                    <th>Enrollment</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredStudents.map((stdnt, index) => {
                                    return (
                                        <tr className="rows" key={index}>
                                            <td>{index + 1}</td>
                                            <td>{stdnt.rN0}</td>
                                            <td>{stdnt.name}</td>
                                            <td>{stdnt.enroll}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    }
                </div>
            </div>
            <a href="/syllabus/2019.pdf" download style={{ display: "inline-block", textAlign: "end", width: "96%" }}>Download 2019 Batch PDF</a>
        </div >
    )
}

export default S2019Batch